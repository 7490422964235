<template>
  <div class="container">
    <div class="tools">
      <div class="tools-search">
        <el-form :inline="true" :model="searchForm" class="tools-search">
          <el-form-item label="专题：">
            <el-input
              v-model.trim="searchForm.themeNoOrTitle"
              size="mini"
              :maxlength="25"
              placeholder="输入专题ID/标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="创建日期：">
            <el-date-picker
              v-model="searchForm.createTime"
              align="right"
              type="date"
              size="mini"
              value-format="yyyy-MM-dd"
              placeholder="请选择创建日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="当前状态：">
            <el-select
              v-model="searchForm.disable"
              size="mini"
              placeholder="请选择专题状态"
            >
              <el-option label="已上架" :value="0"></el-option>
              <el-option label="未上架" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建人：">
            <el-input
              v-model.trim="searchForm.createUserName"
              size="mini"
              :maxlength="25"
              placeholder="请输入创建人"
            ></el-input>
          </el-form-item>
          <div class="tools-button">
            <el-button
              type="primary"
              size="mini"
              :loading="searchLoding"
              @click="onSearch"
              >查询</el-button
            >
            <el-button
              size="mini"
              icon="el-icon-refresh-left
"
              @click="onReset"
              >重置</el-button
            >
          </div>
        </el-form>
      </div>
      <div class="tools__add">
        <el-button size="mini" type="primary" icon="el-icon-plus" @click="onAdd"
          >新增</el-button
        >
        <el-button size="mini" icon="el-icon-delete" @click="onDelAll"
          >删除</el-button
        >
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="dataList"
      border
      :max-height="803"
      tooltip-effect="dark"
      style="width: 100%"
      empty-text="无相关数据"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55"
        :selectable="(e) => e.disable === 1"
      ></el-table-column>
      <el-table-column label="序号" width="50">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop="themeNo"
        label="专题ID"
        width="120"
      ></el-table-column>
      <el-table-column prop="themeTitle" label="专题标题"> </el-table-column>
      <el-table-column prop="createUserName" label="创建人"> </el-table-column>
      <el-table-column prop="createTime" label="创建日期"> </el-table-column>

      <el-table-column prop="disable" label="当前状态">
        <template slot-scope="scope">
          <el-link
            style="cursor: text"
            :type="scope.row.disable === 0 ? 'primary' : ''"
            :underline="false"
            >{{ scope.row.disable === 0 ? "已上架" : "未上架" }}</el-link
          >
        </template>
      </el-table-column>
      <el-table-column label="操作" width="260px">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.disable === 1"
            type="text"
            size="small"
            @click="upOrDownShelves(scope.row)"
            >上架</el-button
          >
          <el-button
            v-if="scope.row.disable === 0"
            type="text"
            size="small"
            @click="upOrDownShelves(scope.row)"
            >下架</el-button
          >
          <el-button
            v-if="scope.row.disable === 1"
            type="text"
            size="small"
            @click="editSpecial(scope.row)"
            >编辑</el-button
          >
          <el-button
            v-if="scope.row.disable === 1"
            type="text"
            size="small"
            @click="onSpecialConfig(scope.row)"
            >配置</el-button
          >
          <el-button
            v-if="scope.row.disable === 1"
            type="text"
            size="small"
            @click="onDel(scope.row)"
            >删除</el-button
          >
          <el-button
            v-if="scope.row.disable === 0"
            type="text"
            size="small"
            @click="onCopy(scope.row)"
            >复制链接</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="page.current"
      :page-size="page.size"
      background
      :page-sizes="[10, 20, 30, 40]"
      layout="total, prev, pager, next, sizes, jumper"
      :total="page.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>

    <el-dialog
      title="基础配置"
      :visible.sync="addVisible"
      :close-on-click-modal="false"
      width="640px"
      @close="onClose"
    >
      <el-form ref="addForm" :model="addForm" inline class="add-award">
        <el-form-item
          label="专题标题："
          required
          prop="themeTitle"
          label-width="100px"
          :rules="[{ required: true, message: '请输入专题标题' }]"
        >
          <el-input
            v-model.trim="addForm.themeTitle"
            size="small"
            :maxlength="15"
            placeholder="请填写专题标题"
            style="width: 368px"
            autocomplete="off"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="addVisible = false">取 消</el-button>
        <el-button
          type="primary"
          size="mini"
          :loading="submitLoding"
          @click="onConfirm"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/mixins/request.js";
import {
  getSpecialList,
  updateUpOrDown,
  deleteSpecial,
  deleteSpecialAll,
  saveSpecial,
} from "@/api/special.js";
import { guid } from "@/utils/index.js";
export default {
  mixins: [request],
  data() {
    return {
      collapse: false,
      selection: [],
      addForm: {},
      addVisible: false,
      submitLoding: false,
    };
  },
  mounted() {
    this.getData(getSpecialList);
  },
  methods: {
    /**
     * 添加事件
     */
    onAdd() {
      this.addVisible = true;
    },
    /**
     * 编辑事件
     */
    editSpecial(row) {
      console.log(row);
      this.addVisible = true;
      this.addForm = {
        themeTitle: row.themeTitle,
        id: row.id,
      };
    },
    /**
     * 添加、编辑专题确认
     */
    onConfirm() {
      this.$refs.addForm.validate((valid) => {
        if (!valid) return false;
        this.submitLoding = true;
        saveSpecial({ data: this.addForm }).then((res) => {
          this.getData();
          this.submitLoding = false;
          this.$message.success(`${this.addForm.id ? "编辑" : "添加"}成功！`);
          this.addVisible = false;
        });
      });
    },
    /**
     * 关闭弹窗，重置表单
     */
    onClose() {
      this.addForm = {};
      this.$refs.addForm.resetFields();
    },

    /**
     * 上架、下架专题
     */
    async upOrDownShelves(row) {
      try {
        await updateUpOrDown({ data: row.id });
        this.$message.success(`${row.disable === 1 ? "上架" : "下架"}成功！`);
        this.getData();
      } catch (error) {}
    },

    /**
     * 批量删除
     */
    async onDelAll() {
      console.log(this.selection);
      if (!this.selection.length)
        return this.$message.error("请选择需要删除的数据~");
      const data = this.selection.map((e) => e.id);

      try {
        await this.confirm();
        await deleteSpecialAll({ data });
        this.$message.success("删除成功！");
        this.getData();
      } catch (error) {}
    },
    /**
     * 删除指定专题
     */
    async onDel(row) {
      if (row.disable === 0) {
        return this.$message.error("无法删除已上架专题");
      }
      try {
        await this.confirm();
        await deleteSpecial({ data: row.id });
        this.$message.success("删除成功！");
        this.getData();
      } catch (error) {}
    },
    onSpecialConfig(row) {
      this.$router.push({
        name: "specialConfig",
        query: { id: row.id },
      });
    },
    /**
     * 选中事件
     */
    handleSelectionChange(e) {
      this.selection = e;
    },
    async confirm() {
      return await this.$confirm("是否确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
    },
    /**
     * 复制当前这条数据的链接至粘贴板
     */
    onCopy(row) {
      const text = `${process.env.VUE_APP_SPECIAL_H5_URL}#/specialTopic?id=${
        row.id
      }&${guid(8)}`;
      if (navigator.clipboard) {
        // clipboard api 复制
        navigator.clipboard.writeText(text);
      } else {
        const textarea = document.createElement("textarea");
        document.body.appendChild(textarea);
        // 隐藏此输入框
        textarea.style.position = "fixed";
        textarea.style.clip = "rect(0 0 0 0)";
        textarea.style.top = "10px";
        // 赋值
        textarea.value = text;
        // 选中
        textarea.select();
        // 复制
        document.execCommand("copy", true);
        // 移除输入框
        document.body.removeChild(textarea);
      }
      this.$message.success("复制成功！");
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 24px 20px;
  margin-top: 10px;
  box-sizing: border-box;
}
.tools {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: space-between;
  &-search {
    overflow: hidden;
    margin-right: 20px;
    ::v-deep .el-input {
      width: 160px;
    }
    ::v-deep .el-form-item {
      margin-bottom: 10px;
    }
    &__item {
      display: flex;
      align-items: center;
      height: 40px;
      margin-right: 20px;
      label {
        width: 90px;
        padding-right: 10px;
        font-size: 14px;
        color: #606266;
        box-sizing: border-box;
        text-align: right;
      }
    }
  }
  &-top {
    display: flex;
  }
  &-bottom {
    display: flex;
  }
  &-button {
    height: 40px;
    display: inline-flex;
    align-items: center;
  }
  &__add {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.tools-search {
  ::v-deep .el-form-item {
    margin-right: 20px !important;
  }
}
</style>
